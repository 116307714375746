import globals from "@/globals";
import router from "@/router";
import { getRole, getToken } from "@/auth";
const whiteListName = ["/login"];

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  const splashScreen = document.querySelector(".app-splash-screen");
  if (splashScreen) {
    splashScreen.style.opacity = 0;
    setTimeout(() => splashScreen && splashScreen.parentNode.removeChild(splashScreen), 300);
  }
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }
  globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
  if (to.path) {
    if (to.path === "/404") {
      document.body.classList.add("app-loading");
      setTimeout(() => next(), 10);
    } else {
      if (getToken()) {
        const yetki = getRole();
        if (to.meta && to.meta[0] && to.meta[0].Roles) {
          const yetkiler = to.meta[0].Roles;
          if (yetkiler.indexOf(yetki) !== -1) {
            document.body.classList.add("app-loading");
            setTimeout(() => next(true), 10);
          } else {
            document.body.classList.add("app-loading");
            setTimeout(() => next({ path: "/" }), 10);
          }
        } else {
          document.body.classList.add("app-loading");
          setTimeout(() => next(true), 10);
        }
      } else {
        if (whiteListName.indexOf(to.path) !== -1 || to.path.includes("pooldonation")) {
          document.body.classList.add("app-loading");
          setTimeout(() => next(true), 10);
        } else {
          document.body.classList.add("app-loading");
          setTimeout(
            () =>
              next({
                path: "/login",
                query: { returnUrl: to.path },
              }),
            10
          );
        }
      }
    }
  } else {
    document.body.classList.add("app-loading");
    setTimeout(() => next({ path: "/404" }), 10);
  }
});
