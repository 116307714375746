// Additional polyfills
import "custom-event-polyfill";
import "url-polyfill";

import Vue from "vue";
import App from "./App";
import router from "./router";
import "@/permission";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import globals from "./globals";
import Popper from "popper.js";

import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import moment from "moment";
import Moment from "moment";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/vendor/libs/vue-multiselect/vue-multiselect.scss";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Vue2Editor from "vue2-editor";
import VueMomentJS from "vue-momentjs";
import flatpickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueClipboard from "vue-clipboard2";
import VueApexCharts from "vue-apexcharts";

Vue.component("apexchart", VueApexCharts);

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Multiselect);
Vue.use(VueGoodTablePlugin);
Vue.use(VueSweetalert2);
Vue.use(Vue2Editor);
Vue.use(VueMomentJS, Moment);
Vue.use(flatpickr);
Vue.use(VueApexCharts);

// Global RTL flag
Vue.mixin({
  data: globals,
  methods: {
    async mesajGoster(msg, icon = "success") {
      await this.$swal({
        icon: icon,
        confirmButtonText: "Tamam",
        title: msg,
      });
    },
    async hataGoster(msg) {
      console.log(msg);
      await this.$swal({
        icon: "error",
        confirmButtonText: "Tamam",
        html: msg,
      });
    },
    formatTarih: function (value) {
      if (!value) {
        return "";
      }
      return moment(value).format("DD.MM.YYYY");
    },
    formatTarihVeSaatForUTC3: function (value) {
      if (!value) {
        return "";
      }
      return moment(value).format("DD.MM.YYYY HH:mm");
    },
    deepCopy(model) {
      return JSON.parse(JSON.stringify(model));
    },
    getDurum(durum) {
      if (durum === 0) {
        return "Pasif";
      }
      if (durum === 2) {
        return "Taslak";
      }
      return "Aktif";
    },
    getYetki(yetki) {
      if (yetki === 64) {
        return "Admin";
      }
      if (yetki === 4) {
        return "Editör";
      }
      if (yetki === 5) {
        return "İletişim Sorumlusu";
      }
      if (yetki === 8) {
        return "Havuz Bağış Sorumlusu";
      }
      return "Proje";
    },
  },
});

window.lokum = new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
