<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">
    <!-- Brand -->
    <b-navbar-brand to="/" class="app-brand">
      <img
        alt=""
        class="app-brand-img"
        style="margin: -10px 0; margin-left: -15px"
        src="https://hayratyardim.org/Content/newDesign/img/logo-dil-destekli.png"
      />
    </b-navbar-brand>

    <!-- Sidenav toggle -->
    <b-navbar-nav class="align-items-lg-center mr-auto mr-lg-4" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <!-- Navbar toggle -->
    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <b-navbar-nav class="align-items-lg-center">
        <!--        <b-nav-item href="#">Link 1</b-nav-item>-->
        <!--        <b-nav-item href="#">Link 2</b-nav-item>-->
      </b-navbar-nav>
      <b-navbar-nav class="align-items-lg-center ml-auto">
        <b-nav-item-dropdown class="demo-navbar-user">
          <template #button-content>
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <img :src="`${publicUrl}vendor/img/2.png`" alt class="d-block ui-w-30 rounded-circle" />
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ name }}</span>
            </span>
          </template>
          <b-dd-item @click="cikisYap"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Çıkış Yap</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { getNameSurname, removeToken } from "@/auth";

export default {
  name: "app-layout-navbar",
  data() {
    return {
      name: getNameSurname(),
    };
  },
  props: {
    sidenavToggle: { type: Boolean, default: true },
  },

  methods: {
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
    cikisYap() {
      removeToken();
      this.$router.push({ name: "login" });
    },
  },
};
</script>
