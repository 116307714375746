export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export function getToken() {
  return localStorage.getItem("token");
}
export function setToken(token) {
  localStorage.setItem("token", token);
}
export function removeToken() {
  localStorage.removeItem("token");
}
export function getRole() {
  var token = getToken();
  if (token === null) {
    return 0;
  }
  var parsedJwt = parseJwt(token);
  return parsedJwt.yetki;
}
export function getNameSurname() {
  var token = getToken();
  if (token === null) {
    return "";
  }
  var parsedJwt = parseJwt(token);
  return parsedJwt.ad_soyad;
}
