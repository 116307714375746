<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <sidenav-router-link icon="ion ion-md-speedometer" to="/" :exact="true" v-if="gosterilecekMi(Yetki.EDITOR, Yetki.EDITOR, Yetki.ADMIN)">Anasayfa</sidenav-router-link>
      <sidenav-router-link
        icon="ion ion-md-cash"
        to="/bagis"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE,Yetki.POOL)"
        :exact="true"
        >Bağış Takibi</sidenav-router-link
      >

      <sidenav-router-link
        icon="ion ion-md-cash"
        to="/bagis/basarisiz"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE)"
        :exact="true"
      >Başarısız Bağış Takibi</sidenav-router-link
      >
      <sidenav-router-link
        icon="ion ion-ios-people"
        to="/bagisci"
        :exact="true"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE)"
        >Bağışçı Yönetimi
      </sidenav-router-link>

      <sidenav-router-link
        icon="ion ion-md-link"
        to="/kisalink"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE)"
        :exact="true"
      >
        Kısa Link
      </sidenav-router-link>

      <!--      <sidenav-menu
        icon="ion ion-md-umbrella"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE)"
        :active="isMenuActive('/sponsorluk')"
        :open="isMenuOpen('/sponsorluk')"
      >
        <template slot="link-text">Sponsorluk Takibi</template>
        <sidenav-router-link
          icon="ion ion-md-radio-button-on"
          v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE)"
          to="/sponsorluk/takip"
          :exact="true"
          >Sponsorluk Takibi
        </sidenav-router-link>
        <sidenav-router-link
          icon="ion ion-md-radio-button-on"
          v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE)"
          to="/sponsorluk/safekey"
          :exact="true"
          >Safekey Güncelleme
        </sidenav-router-link>
        <sidenav-router-link
          icon="ion ion-md-radio-button-on"
          v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE)"
          to="/sponsorluk/odeme-takip"
          :exact="true"
          >Sponsorluk Ödeme Takibi
        </sidenav-router-link>
        <sidenav-router-link
          icon="ion ion-md-radio-button-on"
          v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE)"
          to="/sponsorluk/cekim-log"
          :exact="true"
          >Sponsorluk Çekim Logları
        </sidenav-router-link>
        <sidenav-router-link
          icon="ion ion-md-radio-button-on"
          v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE)"
          to="/sponsorluk/havale-takip"
          :exact="true"
          >EFT / Havale Takibi
        </sidenav-router-link>
        <sidenav-router-link
          icon="ion ion-md-radio-button-on"
          v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE)"
          to="/sponsorluk/tahsilat-dosyasi"
          :exact="true"
          >Tahsilat Dosyası Yükleme
        </sidenav-router-link>
      </sidenav-menu>-->

      <!--      <sidenav-menu
        icon="ion ion-md-card"
        :active="isMenuActive('/tanimlar')"
        :open="isMenuOpen('/tanimlar')"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR)"
      >
        <template slot="link-text">Sponsorluk Tanımları</template>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/tanimlar/sponsorluk" :exact="true"
          >Sponsorluk Tanımları
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/tanimlar/sponsorluk-detay" :exact="true"
          >Sponsorluk Detay Tanımları
        </sidenav-router-link>
      </sidenav-menu>-->

      <sidenav-router-link icon="ion ion-ios-mail" to="/iletisim" :exact="true" v-if="gosterilecekMi(Yetki.ADMIN,Yetki.ILETISIM)">İletişim Yönetimi</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/havuzbagis" :exact="true" v-if="gosterilecekMi(Yetki.POOL)"
      >Havuz Bağışlar
      </sidenav-router-link>
      <sidenav-menu
        icon="ion ion-md-list"
        :active="isMenuActive('/icerik')"
        :open="isMenuOpen('/icerik')"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR)"
      >
        <template #link-text>İçerik Sistemi</template>

        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/etiket" :exact="true"
          >Etiketler
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/havuzbagis" :exact="true" v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE, Yetki.POOL)"
          >Havuz Bağışlar
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/havuzbagiskampanya" :exact="true"
          >Havuz Bağışlar - Kampanya
        </sidenav-router-link>
        <!--
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/program" :exact="true"
          >Programlar
        </sidenav-router-link>
        -->
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/faaliyet" :exact="true"
          >Faaliyetler
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/kampanya" :exact="true"
          >Kampanyalar
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/hizlibagis" :exact="true"
        >Hızlı Bağış
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/popup" :exact="true">
          Popup
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/slider" :exact="true">
          Slider
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/haber" :exact="true"
          >Haberler
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/sms" :exact="true"
          >Smsler
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/gelirgider" :exact="true"
          >Gelir Gider Tablosu
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/bagimsizdenetim" :exact="true"
          >Bağımsız Denetim Tablosu
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/sikcasorulansorular" :exact="true"
          >Sıkça Sorulan Sorular
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/basindabiz" :exact="true"
          >Basinda Biz
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/kurumsalkimlik" :exact="true"
          >Kurumsal Kimlik
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/bildirim" :exact="true"
          >Bildirimler
        </sidenav-router-link>
        <sidenav-menu
          icon="ion ion-md-camera"
          :active="isMenuActive('/icerik/galeri')"
          :open="isMenuOpen('/icerik/galeri')"
        >
          <template #link-text>Galeri Yönetimi</template>
          <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/galeri/fotograf" :exact="true"
            >Fotoğraf
          </sidenav-router-link>
          <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/galeri/video" :exact="true"
            >Video
          </sidenav-router-link>
          <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/galeri/dokuman" :exact="true"
            >Döküman
          </sidenav-router-link>
          <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/galeri/onlarinhikayesi" :exact="true"
            >Onların Hikayesi
          </sidenav-router-link>
        </sidenav-menu>
        <sidenav-menu
          v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR)"
          icon="ion ion-md-information-circle"
          :active="isMenuActive('/icerik/hakkimizda')"
          :open="isMenuOpen('/icerik/hakkimizda')"
        >
          <template #link-text>Hakkımızda</template>
          <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/hakkimizda/hakkimizda" :exact="true"
            >Hakkımızda
          </sidenav-router-link>
          <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/hakkimizda/yonetim-kurulu" :exact="true"
            >Yönetim Kurulu
          </sidenav-router-link>
          <sidenav-router-link icon="ion ion-md-radio-button-on" to="/icerik/hakkimizda/gonullu" :exact="true"
            >Gönüllü Yönetimi
          </sidenav-router-link>
        </sidenav-menu>
      </sidenav-menu>

      <!--      <sidenav-router-link icon="ion ion-ios-call" to="/tavsiyerehberi" :exact="true"
        >Tavsiye Rehberi Yönetimi</sidenav-router-link
      >-->
      <sidenav-router-link
        icon="ion ion-ios-people"
        to="/kullanici"
        :exact="true"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR)"
        >Kullanıcı Yönetimi
      </sidenav-router-link>

      <sidenav-router-link
        icon="ion ion-md-speedometer"
        to="/dil/ceviri"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR)"
        :exact="true"
        >Dil Çeviri</sidenav-router-link
      >

      <sidenav-menu
        icon="ion ion-md-map"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR)"
        :active="isMenuActive('/lokasyon')"
        :open="isMenuOpen('/lokasyon')"
      >
        <template #link-text>Lokasyon Yönetimi</template>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/lokasyon/ulke" :exact="true"
          >Ülke Tanımları
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/lokasyon/il" :exact="true"
          >İl Tanımları
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/lokasyon/ilce" :exact="true"
          >İlçe Tanımları
        </sidenav-router-link>
      </sidenav-menu>

      <sidenav-menu
        icon="ion ion-ios-card"
        v-if="gosterilecekMi(Yetki.ADMIN, Yetki.PROJE, Yetki.EDITOR)"
        :active="isMenuActive('/finans')"
        :open="isMenuOpen('/finans')"
      >
        <template #link-text>Finansal Tanımlar</template>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/finans/bankabilgi" :exact="true"
          >Banka Bilgileri Yönetimi
        </sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio-button-on" to="/finans/bankahesapbilgi" :exact="true"
          >Banka Hesap Bilgileri Yönetimi
        </sidenav-router-link>
      </sidenav-menu>
    </div>
  </sidenav>
</template>

<script>
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider,
} from "@/vendor/libs/sidenav";
import { Yetki } from "@/enums";
import { getRole } from "@/auth";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    /* eslint-enable vue/no-unused-components */
  },
  data() {
    return {
      Yetki,
      developmentMi: process.env.NODE_ENV === "development",
      kullaniciYetki: getRole(),
    };
  },

  props: {
    orientation: { type: String, default: "vertical" },
  },

  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 || bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg.replace(" sidenav-dark", "").replace(" sidenav-light", "").replace("-darker", "").replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal" ? "layout-sidenav" : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    },
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal";
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
    gosterilecekMi() {
      for (let i = 0; i < arguments.length; i++) {
        if (arguments[i] === this.kullaniciYetki) return true;
      }
      return false;
    },
  },
};
</script>
